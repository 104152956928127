import React from 'react';
import { BottomBar as BottomBarStyle, IconWithText } from '../../molecules';
import { withRouter } from 'react-router-dom';
import { DASHBOARD, MOBILE_ACCOUNT } from '../../../routes/private';
import { homeBlue, homeGrey, profileActive, profileInactive } from '../../../assets/icons';
import { RouterNavLink } from '../../../utils/common.styles';
import Ripple from '../../atoms/typography/Ripple';
import Text from '../../atoms/typography/Text';

const Bottombar = ({ location }) => {
  const { pathname } = location;

  const isPathSelected = (path) => pathname === path;

  return (
    <>
      <BottomBarStyle p=" 6px 0px" radius="0px">
        <Ripple>
          <RouterNavLink to={DASHBOARD}>
            <IconWithText mt={1} src={isPathSelected(DASHBOARD) || isPathSelected('/') ? homeBlue : homeGrey} textColor="green" size="md">
              <Text color={isPathSelected(DASHBOARD) || isPathSelected('/') ? 'blue' : 'subtitle'} fontSize="sm" lineHeight="12px" mt={3} bold={isPathSelected(DASHBOARD) || isPathSelected('/')}>Home</Text>
            </IconWithText>
          </RouterNavLink>
        </Ripple>
        <Ripple>
          <RouterNavLink to={MOBILE_ACCOUNT} flexDirection="column">
            <IconWithText mt={1} src={isPathSelected(MOBILE_ACCOUNT) ? profileActive : profileInactive} size="md">
              <Text color={isPathSelected(MOBILE_ACCOUNT) ? 'blue' : 'subtitle'} fontSize="sm" lineHeight="12px" mt={3} bold={isPathSelected(MOBILE_ACCOUNT)}>Profile</Text>
            </IconWithText>
          </RouterNavLink>
        </Ripple>
      </BottomBarStyle>
    </>
  )
}

export default withRouter(Bottombar);