import React, { useState, memo } from 'react';
import NavbarItem from '../../molecules/NavbarItem';
import TopMenu from '../../molecules/TopMenu';
import home_grey from '../../../assets/icons/home-grey.svg';
import home_blue from '../../../assets/icons/home-blue.svg';
import benefits_blue from '../../../assets/icons/benefits-blue.svg';
import benefits_grey from '../../../assets/icons/benefits-grey.svg';
import { notifications as notificationsGrey, notificationsBlue } from '../../../assets/icons'
import {
  DASHBOARD, NOTIFICATIONS, BENEFITS,
} from '../../../routes/private';


const excludedIcons = {
  home: { inactive: home_grey, active: home_blue, link: DASHBOARD },
  benefits: {
    inactive: benefits_grey, active: benefits_blue, link: BENEFITS
  },
  notifications: { inactive: notificationsGrey, active: notificationsBlue, link: NOTIFICATIONS }
}

const AnimatedNavbar = ({ history }) => {
  const [icons, updateIcons] = useState({ home: excludedIcons['home'].inactive });
  const [animationState, updateAnimationState] = useState({
    activeIndices: {},
    animatingOut: false
  });

  const reapplyDropdownState = (currentIndex) => {
    updateAnimationState({
      ...animationState,
      activeIndices: {
        currentIndex,
        prevIndex: animationState.activeIndices.currentIndex
      },
      animatingOut: false
    });
  };

  const onMouseLeave = () => {
    updateAnimationState({
      ...animationState,
      animatingOut: true
    })
    // Giving sometime for animation to get completed before reseting dropdownstate.
    window.setTimeout(reapplyDropdownState, 500)
  };

  const mouseEnter = (feature) => {
    reapplyDropdownState()
    updateIcons({ ...icons, [feature]: excludedIcons[feature].active })
  };

  const mouseLeave = (feature) => {
    reapplyDropdownState()
    updateIcons({ ...icons, [feature]: excludedIcons[feature].inactive })
  };

  return (
    <TopMenu onMouseLeave={onMouseLeave}>
      <NavbarItem
        onMouseEnter={() => mouseEnter('home')}
        onMouseLeave={() => mouseLeave('home')}
        link={{ to: DASHBOARD }}
        title="Home"
        icon={{ src: [DASHBOARD, '/'].includes(history.location.pathname) ? excludedIcons['home'].active : icons['home'] }}
      />
    </TopMenu>
  );
};

export default memo(AnimatedNavbar);