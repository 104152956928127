/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { UserDetailsContext } from '../contexts';
import HomeAPI from '../../services/home.api';

const useUserDetails = (refresh=false) => {
  const { userDetails: { user, familyMembers, familyDoctor={}, services={}, rewards={}, stepathon={}, productType, other_members_info={}, valuePlusSubscriber, valuePlusPastSubscriber, expiringRewardPoints, totalValuePlusSavings, activeOrExpiredCustomerSubscription, company_logo, partner_logo, checks, customer_support={}, allow_only_sodexo_and_pluxee_payment, auto_allocate_provider }, updateUserDetails, updateDashboardData, } = useContext(UserDetailsContext)
  const [isLoading, updateIsLoading] = useState(refresh)
  const [isError, updateIsError] = useState(false)
  // TODO:: change api to provide name of present user or change API.
  useEffect(() => {
    if(isLoading) {
      fetchAndUpdateUserDetails()
    }
  }, [isLoading])

  const fetchAndUpdateUserDetails = async() => {
    try {
      const responses = await fetchData()
      const { family_doctor, value_plus_subscriber, past_value_plus_subscriber, value_plus_savings, active_or_expired_customer_subscription, reward_points, rewards_active, expiring_reward_points, ...user } = responses[0].data.members.find((member) => member.is_logged_in === true) || {};
      const { members, services, company_logo, partner_logo, customer_support, allow_only_sodexo_and_pluxee_payment, auto_allocate_provider, ...other_info } = responses[0].data;
      updateUserDetails({
        user,
        familyMembers: members,
        familyDoctor: family_doctor || {},
        services: services,
        other_members_info: other_info,
        rewards: { rewardsPoints: reward_points, rewardsActivated: rewards_active },
        valuePlusSubscriber: value_plus_subscriber,
        valuePlusPastSubscriber: past_value_plus_subscriber,
        totalValuePlusSavings: value_plus_savings || 0,
        activeOrExpiredCustomerSubscription: active_or_expired_customer_subscription || {subscription_cost: 99, end_date: new Date(), start_date: new Date()},
        expiringRewardPoints: expiring_reward_points,
        company_logo, partner_logo,
        customer_support: customer_support,
        checks: responses[1]?.data ?? {},
        allow_only_sodexo_and_pluxee_payment: allow_only_sodexo_and_pluxee_payment,
        auto_allocate_provider: auto_allocate_provider 
      })
    }catch(err) {
      updateIsError(true)
    }finally {
      updateIsLoading(false)
    }
  }

  const planId = window.localStorage.getItem('planId')

  const callFamilyMembersAPI = () => {
    HomeAPI.fetchFamilyMembers({planId}, (response) => {
      if(response.status === 200) {
        const { family_doctor, value_plus_subscriber, past_value_plus_subscriber, value_plus_savings, active_or_expired_customer_subscription, reward_points, expiring_reward_points, rewards_active, ...user } = response.data.members.find((member) => member.is_logged_in === true) || {};
        const { members, services, company_logo, partner_logo, customer_support, auto_allocate_provider, ...other_info } = response.data;
        updateUserDetails({
          user,
          familyMembers: members,
          familyDoctor: family_doctor || {},
          services: services,
          other_members_info: other_info,
          rewards: { rewardsPoints: reward_points, rewardsActivated: rewards_active },
          valuePlusSubscriber: value_plus_subscriber,
          valuePlusPastSubscriber: past_value_plus_subscriber,
          totalValuePlusSavings: value_plus_savings || 0,
          activeOrExpiredCustomerSubscription: active_or_expired_customer_subscription || {subscription_cost: 99, end_date: new Date(), start_date: new Date()},
          expiringRewardPoints: expiring_reward_points,
          company_logo, partner_logo,
          customer_support: customer_support,
          allow_only_sodexo_and_pluxee_payment: allow_only_sodexo_and_pluxee_payment,
          checks: checks,
          auto_allocate_provider: auto_allocate_provider
        })
        updateIsLoading(false)
      }
    }, () => {
      updateIsError(true)
      updateIsLoading(false)
    })
  }


  const fetchData = () => Promise.all([HomeAPI.fetchFamilyMembers({planId}), HomeAPI.fecthNewUserToPharmacy({})])

  const isAuthenticated = user && user.id

  // TODO:: This one for only GSK client chnages handle for banner and wallet  amout show and hide will remove in feature.
  const hasGSKSponsor = [2823, 2822, 2795].includes(user?.sponsor || 0);

  return {
    isLoading, isError, user, familyDoctor, familyMembers, company_logo, partner_logo, customer_support,isAuthenticated, updateUserDetails, services, updateDashboardData, other_members_info,
    requireDataUpdation: updateIsLoading, rewards, stepathon, productType, valuePlusSubscriber, valuePlusPastSubscriber, expiringRewardPoints, totalValuePlusSavings, activeOrExpiredCustomerSubscription,
    checks, callFamilyMembersAPI, hasGSKSponsor, allow_only_sodexo_and_pluxee_payment, auto_allocate_provider
  };
}

export default useUserDetails;
