import React, { Fragment, useEffect, useReducer, useCallback } from 'react';
import theme from '../../themes/index';
import { ThemeProvider } from 'styled-components';
import { Toast as ToastContainer } from '../../components/molecules';
import toast from '../toast';
import { UserDetailsContext } from '../contexts';
import serviceWorkerConfig from '../../utils/serviceWorkerConfig';
const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
const UPDATE_DASHBOARD_DATA = 'UPDATE_DASHBOARD_DATA';
const UPDATE_USER_NOTIFICATIONS = 'UPDATE_USER_NOTIFICATIONS';

const reducer = (state, action) => {
  const { type, ...rest } = action;
  switch (type) {
  case UPDATE_USER_DETAILS:
    return {
      ...state,
      user: rest.data.user,
      familyMembers: rest.data.familyMembers,
      other_members_info: rest.data.other_members_info,
      familyDoctor: rest.data.familyDoctor,
      services: rest.data.services,
      rewards: rest.data.rewards,
      valuePlusSubscriber: rest.data.valuePlusSubscriber,
      valuePlusPastSubscriber: rest.data.valuePlusPastSubscriber,
      totalValuePlusSavings: rest.data.totalValuePlusSavings,
      activeOrExpiredCustomerSubscription: rest.data.activeOrExpiredCustomerSubscription,
      company_logo: rest.data.company_logo, 
      partner_logo: rest.data.partner_logo,
      checks: rest.data.checks,
      customer_support: rest.data.customer_support,
      allow_only_sodexo_and_pluxee_payment: rest.data.allow_only_sodexo_and_pluxee_payment,
      auto_allocate_provider: rest.data.auto_allocate_provider
    }
  case UPDATE_DASHBOARD_DATA:
    return { ...state, stepathon: rest.data.stepathon }
  case UPDATE_USER_NOTIFICATIONS:
    return {...state, notifications: rest.data.notifications, notificationsRecentCount: rest.data.count}
  default:
    return {...state, ...rest}
  }
}
// defaultUserDetails widely used for tests.
const withContextHOC = (Component, defaultUserDetails={user: {}, familyMembers: null, familyDoctor: {}, notifications: {}, notificationsRecentCount: 0, rewards: {}, other_members_info: {}, hasPrescriptionPending: false, valuePlusSubscriber: false, company_logo: '', partner_logo: '', checks: {}, allow_only_sodexo_and_pluxee_payment: false}) => (props) => {
  const [userDetails, dispatch] = useReducer(reducer , {...defaultUserDetails})
  useEffect(() => {
    let offlineToastId = null;
    const displayOffline = () => {
      offlineToastId = toast.default('You are offline. Please check your internet connection', {pause: true})
    }
    const displayOnline = () => {
      if(offlineToastId) toast.remove(offlineToastId)
      // toast.success('Your connection is back online. wish you healthy browsing ahead :)')
    }
    const networkRecommendations = (networkInfo) => {
      const { effectiveType } = networkInfo;
      if(('onLine' in (window.navigator || {}) && window.navigator.onLine) && ['slow-2g', '2g'].indexOf(effectiveType) !== -1) {
        toast.default('Slow connection detected.')
      }
    }
    /**
     * checks for presence of navigator connection which has ability to show the type of connection.
     * but it's not supported by all browsers except chrome. so using window.navigator.onLine and
     * adding in eventlisteners on user going online and offline which is supported by all browsers except opera mini.
     * References: https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation#Properties
     * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine#Browser_compatibility
     */
    if('connection' in (window.navigator || {})) {
      networkRecommendations(window.navigator.connection) // checks on page load for connection speed for recommendations.
      window.navigator.connection.onchange = (event) => networkRecommendations(event.currentTarget)
    }
    window.addEventListener('offline', displayOffline)
    window.addEventListener('online', displayOnline)

    return () => {
      if('connection' in (window.navigator || {})) {
        window.navigator.connection.onchange = null;
      }
      window.removeEventListener('offline', displayOffline)
      window.removeEventListener('online', displayOnline)
    }
  }, [])

 
  // Setinterval to check for updated service worker.
  // And updates service worker if a new service worker is present.
  // visit the links for reference:
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
  // https://gist.github.com/Rich-Harris/fd6c3c73e6e707e312d7c5d7d0f3b2f9#navigatorserviceworkercontroller-is-the-service-worker-that-intercepts-fetch-requests
  /** Avoid force update of service-worker to avoid 500 error when user route to a page.
    UNCOMMENT this code when you show toast to reload the existing page.
   */
  useEffect(() => {
    let timeIntervalId;
    if('serviceWorker' in navigator) {
      timeIntervalId = window.setInterval(() => {
        if(window.navigator.serviceWorker.getRegistration) {
          window.navigator.serviceWorker.getRegistration('/serviceworker.js').then(function(registration) {
            registration?.update && registration.update().catch(window.console.info)
            serviceWorkerConfig.onUpdate(registration, true)
          }).catch(function() {
            window.console.info(`updating worker failed`)
          })
        }
      }, 30000)
    }
    return () => timeIntervalId && window.clearInterval(timeIntervalId)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <UserDetailsContext.Provider value={{
        updateUserDetails: useCallback((data) => dispatch({ type: UPDATE_USER_DETAILS, data}), []), userDetails,
        updateUserNotifications: useCallback((data) => dispatch({ type: UPDATE_USER_NOTIFICATIONS, data}), []),
        updateDashboardData: useCallback((data) => dispatch({ type: UPDATE_DASHBOARD_DATA, data}), [])
      }}>
        <Fragment>
          <Component {...props}/>
          <ToastContainer />
        </Fragment>
      </UserDetailsContext.Provider>
    </ThemeProvider>
  )
}

export default withContextHOC;
