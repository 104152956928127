import React, { useState } from 'react';
import FreshChat from './react-freshchat';
import { Button, Card, Icon, ToggleVisibility } from '../components/atoms';
import { helpSupportProfile } from '../assets/icons';
import HelpFooter from '../components/molecules/HelpFooter';
import IconWithText from '../components/molecules/IconWithText';
import { Text } from '../components/atoms/typography';
import ListsWithHeader from '../components/organisms/lists/WithHeader';
import ReviewDetailPageLoader from '../components/organisms/loaders/ReviewDetailPageLoader';
import { useUserDetails } from './customHooks';
import ContactSupportList from '../components/molecules/ContactSupportList';
import { useHistory } from "react-router-dom";
import { HELPSUPPORT } from '../routes/private';

const NewFreshChat = ({ type, helpCardtext, helpCardBackgroundImage, helpCardMins, helpCardFooterProps, onClickHandler = () => {}, postOpenCallback = () => {}, maxWidth }) => {
  const { push} = useHistory();
  const [showChat, setShowChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user: { first_name, last_name, email, alternative_mobile_number, customer_id, sponsor}, customer_support } = useUserDetails();
  const supportText = loading ? 'Connecting...' : 'Chat with us';
  
  const postOpenHandler = () => {
    setLoading(false);
    postOpenCallback();
  }

  const openSupportChat = () => {
    let nativeEventData = JSON.stringify({ action: 'open-support', payload: {} })
    window.postAndroidMessage(nativeEventData)
    window.postIOSMessage(nativeEventData)
  }

  const waitForFcWidget = () => new Promise(resolve => {
    const checkInterval = setInterval(() => {
      if (window.fcWidget) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 1);
  });

  const desktopOnClickHandler = async () => {
    if (window.fcWidget?.isOpen()) return;
    if (!window.fcWidget?.isInitialized()) setLoading(true);
    setShowChat(true);
    onClickHandler();
    try {
      await waitForFcWidget();
      window.fcWidget.on('widget:opened', postOpenHandler);
      window.fcWidget.open();
    } catch (error) {
      console.error('Failed to load fcWidget.', error);
      setLoading(false);
    }
  };

  const handleChatButtonClick = () => {
    if(type==='mobile_profile_section_support'){
      push(HELPSUPPORT)
    }
    if(customer_support.type_of_support === 'company_support'){
      return
    }
    window.isEkincareApp() ? openSupportChat() : desktopOnClickHandler()
  }
  
  const onFreshChatInit = widget => {
    widget.user.setProperties({
      first_name, last_name, email, alternative_mobile_number, customer_id, sponsor
    });
  };

  const HeaderChatMenu = () => (
    <div className='flex py-1.5 px-5 hover:bg-blue-100 rounded-lg cursor-pointer mr-1' onClick={handleChatButtonClick}>
      <ToggleVisibility visibleOn={{ desktop: true, tablet: false, mobile: false }} className='flex flex-col'>
        <IconWithText alignSelf={'center'} mr={3} size='lg' flexDirection='row' src={helpSupportProfile}><Text color='extraInformation' lineHeight='0px' fontSize='md' bolder>{customer_support.support_email || customer_support.support_mobile_number ? 'Contact support': supportText}</Text></IconWithText>
        <Text alignSelf='center' whiteSpace='nowrap' color='subtitle' fontSize={'xs'} bold>{(customer_support.support_mobile_number && customer_support.support_email)? `${customer_support.support_mobile_number} · ${customer_support.support_email}` : (customer_support.support_mobile_number || customer_support.support_email || 'Mon - Sat | 8:00 AM - 6:00 PM')}</Text>
      </ToggleVisibility>
      <ToggleVisibility visibleOn={{ desktop: false, tablet: true, mobile: false }} className='flex flex-col'>
        <Icon mr={3} size='xlmd' flexDirection='row' src={helpSupportProfile}/>
      </ToggleVisibility>
    </div>
  )
  
  const renderChatButton = () => {
    const cellOptions2 = [{ type: 'action', header: 'Help and support', subHeader: '', icon: { src: helpSupportProfile, required: true, size: 'xxml' }, onClick: handleChatButtonClick }]
    const cellOptions = [{ type: 'action', header: 'Help and Support', subHeader: 'Mon - Sat | 8 AM - 6 PM', icon: { src: helpSupportProfile, required: true, size: 'xxml' }, onClick: handleChatButtonClick }]
    switch (type) {
    case 'button':
      return (
        <Button onClick={handleChatButtonClick} letterSpacing='0px' variant='primary' fontSize='md' width='170px' height='44px' bold>
          {supportText}
        </Button>
      );
    case 'header':
      return (
        customer_support && customer_support.type_of_support === 'no_support' ? null : <HeaderChatMenu />
      );
    case 'footer_card':
      return (
        <HelpFooter helpCardClickHandler={handleChatButtonClick} text={helpCardtext} mins={helpCardMins} footerProps={helpCardFooterProps} backgroundImage={helpCardBackgroundImage} loading={loading} maxWidth={maxWidth} />
      );
    case 'button_support':
      return (
        <ContactSupportList/>
      );
    case 'mobile_profile_section_support':
      return (
        <Card borderRequired={false} mt={-4} >
          <ListsWithHeader listCellOptions={cellOptions2}/>
        </Card>      
      );
    case 'mobile_profile_section':
      return (
        <Card borderRequired={false} mt={-4}>
          {loading ? 
            <ReviewDetailPageLoader SpinnerRequired={false} text={'Connecting...'} position={'relative'} backgroundOpacity={'opacity-100'} height={'h-20'} textFontWeight='bolder' textFontSize='mdL' /> 
            :
            <ListsWithHeader listCellOptions={cellOptions}/>
          }
        </Card>        
      );
    default:
      return null;
    }
  };

  return (
    <>
      {renderChatButton()}
      {showChat && (
        <FreshChat
          token="01b94c77-80cf-4ed8-bc54-90dc7422837a"
          config={{ headerProperty: { direction: 'rtl', hideChatButton: true } }}
          onInit={onFreshChatInit}
        />
      )}
    </>
  );
};

export default NewFreshChat;