import axios from 'axios';
import errorDecorator from './errorDecorator';
import { initiateAxiosObject } from './development.setup';
import { clientSource } from './common';

// axios.defaults.xsrfCookieName = "_csrf_token";
// should only be used on development environment.
// axios.defaults.xsrfHeaderName = "X-CSRF-Token";
// if (!process.env.REACT_APP_API_URL) axios.defaults.withCredentials = true;
// if (window.isEkincareApp()) axios.defaults.withCredentials = false;

axios.defaults.withCredentials = true;

const request = async (options, onSuccess, onError) => {
  const { headers, ...restOptions } = options;
  let source = clientSource();
  let axiosInstance = null;
  if (process.env.REACT_APP_API_URL || window.isEkincareApp()) {
    axiosInstance = initiateAxiosObject({ headers: { ...headers, 'source': source, 'app-version': '23.12.2' }, options: restOptions })
  } else {
    if (options['method'] === 'post' || options['method'] === 'delete' || options['method'] === 'patch' || options['method'] === 'put') {
      axiosInstance = initiateAxiosObject({ headers: { ...headers, 'source': source }, options: restOptions })
    } else {
      axiosInstance = initiateAxiosObject({ headers: { ...headers, 'source': source }, options: restOptions })
    }
  }

  const client = axios.create(axiosInstance)

  options = {
    ...restOptions
  }

  const requestPromise = client(options)
  if (onSuccess) {

    /*
      returns promise itself instead of resolving here
      if onsuccess is not passed as argument.
    */
    return requestPromise
      .then((response) => {
        if ((response?.headers['rx-pending-for-consultations'] ?? false) === "true") window.localStorage.setItem("HAS_PRESCRIPTION_PENDING", "TRUE")
        else window.localStorage.setItem("HAS_PRESCRIPTION_PENDING", "FALSE");
        onSuccess(response)
      })
      .catch((error, ...rest) => {
        if (onError) onError(errorDecorator(error), ...rest)  
        if(window.Sentry) {
          window.Sentry.captureException(options)
        }
      })
  }
  return requestPromise
}

export default request;
